import { captureException } from 'shared/sentry'
import { isProductionEnvironment } from 'utils/isProductionEnvironment'

type ConcentType = 'performance' | 'advertising' | 'denied'
type EventType = 'finimize_consent_update'

type Event = {
    event: EventType
    finimize_authorized_cookies: ConcentType[]
}

declare global {
    interface Window {
        dataLayer:
            | {
                  push: (event: any) => void
              }
            | undefined
    }
}

/**
 * This class is used to manage interactions with Google Tag Manager (GTM) data layer.
 * GTM is initialized via a script in _app.tsx, but events are only sent after
 * the user has given consent in the cookie modal.
 *
 * The class provides methods to authorize tracking and send events to the GTM data layer,
 * ensuring that user privacy preferences are respected.
 */
class GTMClient {
    /**
     * Sends an event to the Google Tag Manager data layer.
     * @param event The event object to be sent to GTM.
     */
    track = async (event: Event): Promise<void> => {
        if (!isProductionEnvironment) return

        if (!event.finimize_authorized_cookies || event.finimize_authorized_cookies.length === 0) {
            captureException(
                Error(`No authorized cookies specified. Event: ${JSON.stringify(event)}`),
            )
            return
        }

        try {
            if (!window.dataLayer) {
                throw new Error('GTM data layer not found')
            }
            window.dataLayer.push(event)
        } catch (e) {
            captureException(e)
        }
    }
}

const gtmClient = new GTMClient()
export default gtmClient
