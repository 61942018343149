import type { NextPageContext } from 'next'
import { fetchQuery } from 'react-relay'
import type { Environment } from 'relay-runtime'

import checkUser from './checkUser'
import { authRedirect } from './redirect'

import { routeMap } from 'shared/routes'
import { captureException } from 'shared/sentry'

type Options = {
    redirectKeyIfNoAuth?: string
    shouldRedirectIfLoggedIn?: boolean
}

type GenericProps = {
    [key: string]: any
}

type GenericRelayQuery = { response: GenericProps; variables: {} }

// This is a bit complicated/tenuous now as there are multiple places auth logic
// can live, and it relies on the relay query including that information.
// Might be better to switch to a token-based approach
export const checkAuthenticationFromRelayProps = (props?: GenericProps) => {
    const authorization = props?.viewer?.authorization
    return !!props?.viewer?.me || authorization?.roles?.isAnonymous === false
}

async function handleAuth(
    ctx: NextPageContext,
    environment: Environment,
    options: Options,
): Promise<boolean> {
    if (options.redirectKeyIfNoAuth || options.shouldRedirectIfLoggedIn) {
        try {
            const data: GenericProps = await fetchQuery<GenericRelayQuery>(
                environment,
                checkUser,
                {},
                // @ts-ignore Note toPromise is not recommended by Relay and will break with future functionality
            ).toPromise()
            const isAuthenticated = checkAuthenticationFromRelayProps(data)
            // Should look at a better way of achieving this /  making it more foolproof
            const hasPremium =
                isAuthenticated &&
                (data.viewer?.me?.hasPremiumSubscription ||
                    data.viewer?.authorization?.roles?.hasPremiumSubscription ||
                    data.viewer?.me?.authorization?.roles?.hasPremiumSubscription)

            // if we are not logged in,
            // and trying to access an auth-required page
            // redirect to given url
            if (options.redirectKeyIfNoAuth) {
                if (!isAuthenticated) {
                    authRedirect(ctx, options.redirectKeyIfNoAuth)
                    return true
                }
                // if we are logged in,
                // and trying to access a non-auth page:
                // if slug and not premium => redirect to checkout with slug
                // otherwise => redirect to home
            } else if (options.shouldRedirectIfLoggedIn) {
                if (isAuthenticated) {
                    const {
                        query: { slug },
                    } = ctx

                    if (!hasPremium && slug) {
                        // redirecting with a routeMap with a slug with **/**/ subdomains such as plans/checkout/
                        // does not work, thus opt to pass in coded string.
                        authRedirect(ctx, routeMap.plansCheckout(slug as string))
                    } else {
                        authRedirect(ctx, routeMap.home)
                    }

                    return true
                }
            }

            return false
        } catch (error) {
            captureException(error)
        }
    }

    return false
}

export default handleAuth
